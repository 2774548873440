
export interface IApiResponse {
  statusCode: number
}

export const ok = (status: number) =>
  status >= 200 && status < 300

export const postJSON = async (endpoint: string, payload?: object) => {
  const response = await fetch(`${endpoint}`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(payload || {})
  })

  if (!ok(response.status))
    throw new Error(response.statusText ?? response.status.toString())

  return response
}

export const getJSON = async (endpoint: string) => {
  const response = await fetch(`${endpoint}`)

  if (!ok(response.status))
    throw new Error(response.statusText ?? response.status.toString())

  return response
}

export const asJSON = async  <T extends Object>(response: Response): Promise<T> => {
  const json = await response.json()

  if (json.statusCode && !ok(json.statusCode))
    throw new Error(json.statusText ?? json.statusCode)

  return json
}
