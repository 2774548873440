import m from "mithril"
// import { ILink } from "@baseline/utils/types"
import { debounce } from "oj-promise-utils"
// import { ea } from "@baseline/components/ecommerceTracking/eventAggregator"
// import { asJSON, get, replaceApiArgs } from "@baseline/utils/fetch"

import { asJSON, getJSON } from "../../fetch"

interface IResult {
    results: any,
    suggestions: any[]
}

export interface Itranslations {
    searchLabel?: string
    searchFor?: string
    searchResults?: string,
    suggestions?: string
    searchNoResults?: string
    SearchIntroduction?: string
}

interface ISearchState {
    apiEndpoint: string
    suggestions: any[]
    translations: Itranslations
    searchTerm: string
    results: any[]
    submitted: boolean
    idleTimer: any
}

const state: ISearchState = {
    apiEndpoint: "",
    suggestions: [],
    translations: {},
    searchTerm: null,
    results: [],
    submitted: false,
    idleTimer: null
}

// Setters
export const setApiEndpoint = (apiEndpoint: string) =>
    state.apiEndpoint = apiEndpoint

export const setSearchTerm = (searchTerm: string) =>
    state.searchTerm = searchTerm

export const setResults = (results: any[]) =>
    state.results = results.map(x => ({
        title: (x.title as string).replaceAll(state.searchTerm.toLocaleLowerCase(), `<em>${state.searchTerm.toLocaleLowerCase()}</em>`),
        url: x.uri
    }))

export const setSubmitted = (submitted: boolean) =>
    state.submitted = submitted

export const setIdleTimer = (x: any) =>
    state.idleTimer = x

export const setSuggestions = (suggestions: any[]) =>
    state.suggestions = suggestions.map(x => ({
        title: x.title,
        url: x.uri
    }))

export const setTranslations = (translations: Itranslations) =>
    state.translations = translations

// Getters
export const getApiEndpoint = () =>
    state.apiEndpoint

export const getSearchTerm = () =>
    state.searchTerm

export const getResults = () =>
    state.results

export const getSubmitted = () =>
    state.submitted

export const getIdleTimer = () =>
    state.idleTimer

export const getSuggestions = () =>
    state.suggestions

export const getTranslations = () =>
    state.translations

// Functions

export const sendGTM = (searchTerm: string, submitted: boolean) => {
    // ea.emit(submitted ? "gtm:search" : "gtm:unsubmitSearch", {
    //     location_id: "Header search bar",
    //     term: searchTerm,
    // })

    window.ojtag("search_event", { searchTerm, submitted })
};

export const search = debounce(500, async (redirect: boolean = false) => {
    if (getSearchTerm().length < 3) {
        setResults([])
        setSuggestions([])
        m.redraw()
        return
    }

    if (!window.has("beforeunload.search"))
        window.on("beforeunload.search", () =>
            sendGTM(getSearchTerm(), getSubmitted())
        )

    const result = await getJSON(`${state.apiEndpoint}${getSearchTerm()}`).then((x) => asJSON<IResult>(x))

    if (redirect) {
        const url = result?.results[0].uri;

        if (url) {
            setSubmitted(true);
            document.location.href = url
        }
    }
    else {
        setResults(result.results)
        setSuggestions(result.suggestions)
    }

    setIdleTimer(
        setTimeout(() => {
            if (!getSubmitted())
                sendGTM(getSearchTerm(), false)
        }, 15000)
    )

    m.redraw()
});

