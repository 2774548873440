// import { ea } from "@baseline/components/ecommerceTracking/eventAggregator"
import { cleanString, debounce } from "oj-utils"
import m from "mithril"
import { MithrilTsxComponent } from "mithril-tsx-component"
import * as Logic from "./logic"
import { redrawOnBreakpoint } from "../../mobile"
import { Svg } from "../../svg"
import { mounter, hideScrollbars } from "../../utils"
import { getTranslation } from "./translation"

let isMobile = redrawOnBreakpoint(1023);
let isMedium = redrawOnBreakpoint(1130);

interface ISearch {
    apiEndpoint: string,
}

export class Search extends MithrilTsxComponent<ISearch> {
    open: boolean = false;
    headerContainer: HTMLElement

    oninit(v: m.Vnode<ISearch>) {
        this.headerContainer = document.querySelector<HTMLElement>(".header");

        Logic.setApiEndpoint(v.attrs.apiEndpoint);
    }

    view(v: m.Vnode<ISearch>) {
        return <div className={cleanString("search-component", this.open && "open")}>
            {isMobile()
                ? [
                    <button className="trigger" type="button" aria-label="search" onclick={() => this.openSearch(v, !this.open)}>
                        <Svg src="/FrontendWebpack/dist/assets/newIcons/search.svg" />
                    </button>,
                    <SearchOverlay open={(x) => this.openSearch(v, x)} />
                ]
                : <SearchBar isOpen={this.open} open={(x) => this.openSearch(v, x)} />
            }
        </div>
    }

    onupdate(v: m.Vnode<ISearch>) {
        this.headerContainer.classList.toggle("search-open", this.open);
    }

    openSearch(v, open: boolean) {
        this.open = open
        isMobile() && hideScrollbars(this.open)

        if (this.open) {
            const input = v.dom.querySelector(".input") as HTMLInputElement
            input.focus()
            this.clickOutside(v)
        }
    }

    clickOutside(v) {
        if (!this.open)
            return

        document.on("click.search", (e) => {
            const isClickInside = v.dom.contains(e.target)

            if (isClickInside)
                return

            document.off("click.search")
            this.open = false
            document.body.classList.remove("search-active")
            m.redraw()
        })
    }
}

interface ISearchBar {
    open: (x: boolean) => void
    isOpen: boolean
}

class SearchBar extends MithrilTsxComponent<ISearchBar> {
    view(v: m.Vnode<ISearchBar>) {
        return <div className="search-bar">
            <div className="input-container">
                <input className="input" type="text" aria-label={getTranslation("searchLabel")} placeholder={isMedium() ? `${getTranslation("searchLabel")}...` : getTranslation("searchFor")} value={Logic.getSearchTerm()}
                    onfocus={(e) => {
                        document.body.classList.add("search-active")
                        Logic.setSearchTerm(e.target.value)
                        Logic.search()
                        clearTimeout(Logic.getIdleTimer());
                    }}
                    onblur={() => {
                        if (v.attrs.isOpen === false)
                            document.body.classList.remove("search-active")
                    }}
                    oninput={(e) => {
                        // if (e.target.value.length > 2)
                        //    
                        v.attrs.open(true)
                        Logic.setSearchTerm(e.target.value)
                        Logic.search()
                        clearTimeout(Logic.getIdleTimer());
                    }}
                    onkeypress={(e) => {
                        if (e.key === "Enter")
                            Logic.search(true)
                    }} />
                {Logic.getSearchTerm()?.length > 0 && <button className="clear" aria-label={getTranslation("clear")} onclick={() => Logic.setSearchTerm("")}>
                    <Svg src="/FrontendWebpack/dist/assets/newIcons/clear.svg" />
                </button>}
                <div className="search-icon">
                    <Svg src="/FrontendWebpack/dist/assets/newIcons/search.svg" />
                </div>
            </div>

            <SearchPanel />
        </div>
    }
}

class SearchPanel extends MithrilTsxComponent<{}> {
    view(v) {
        return <div className="search-panel">
            {Logic.getSearchTerm()?.length > 2 && <div className="section result">
                {Logic.getResults().length > 0
                    ? <ul className="results">
                        {Logic.getResults().map(x => <li><a class="item" href={x.url} target="_self" onclick={() => Logic.setSubmitted(true)}>{m.trust(x.title)}</a></li>)}
                    </ul>
                    : Logic.getSearchTerm()?.length > 3
                        ? <p>{getTranslation("searchNoResults")}</p>
                        : <p>{getTranslation("searchIntroduction")}</p>
                }
            </div>}
            {Logic.getSuggestions().length > 0 && <div className="section">
                <span className="title">{getTranslation("suggestions")}</span>
                <ul className="link-cloud">
                    {Logic.getSuggestions().map(x => <li><a class="item" href={x.url} target={x.target} onclick={() => Logic.sendGTM(x.title, true)}>{x.title}</a></li>)}
                </ul>
            </div>}
        </div>
    }
}

interface ISearchOverlay {
    open: (x: boolean) => void
}
class SearchOverlay extends MithrilTsxComponent<ISearchOverlay> {
    view(v: m.Vnode<ISearchOverlay>) {
        return <div className="search-overlay">
            <div className="input-container">
                <button className="close" aria-label={getTranslation("searchIntroduction")} onclick={() => v.attrs.open(false)}>
                    <Svg src="/FrontendWebpack/dist/assets/newIcons/arrow-left.svg" />
                </button>
                <input className="input" type="text" placeholder={getTranslation("searchFor")} value={Logic.getSearchTerm()}
                    onclick={() => v.attrs.open(true)}
                    oninput={(e) => {
                        Logic.setSearchTerm(e.target.value)
                        Logic.search()
                        clearTimeout(Logic.getIdleTimer());
                    }}
                    onkeypress={(e) => {
                        if (e.key === "Enter")
                            Logic.search(true)
                    }} />
                {Logic.getSearchTerm()?.length > 0 && <button className="clear" aria-label={getTranslation("clear")} onclick={() => Logic.setSearchTerm("")}>
                    <Svg src="/FrontendWebpack/dist/assets/newIcons/clear.svg" />
                </button>}
                <div className="search-icon">
                    <Svg src="/FrontendWebpack/dist/assets/newIcons/search.svg" />
                </div>
            </div>

            <SearchPanel />
        </div>
    }
}

mounter("[data-m-search]", (r, d = {}) => {
    const data = {
        apiEndpoint: `${window.location.origin}/api/search/`,
    }

    m.mount(r, {
        oncreate: dom => r.replaceWith(dom.dom),
        view: v => <Search {...data} />
    })
})