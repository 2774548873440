const NL = {
    searchLabel: "zoeken",
    searchFor: "waar ben je naar op zoek?",
    suggestions: "suggesties",
    searchNoResults: "geen resultaten",
    searchIntroduction: "Zoek in ons assortiment",
    close: "sluiten",
    clear: "leeg maken"

}

const FR: typeof NL = {
    searchLabel: "chercher",
    searchFor: "que recherchez-vous ?",
    suggestions: "suggestions",
    searchNoResults: "aucun résultat",
    searchIntroduction: "Rechercher notre gamme",
    close: "fermer",
    clear: "vide"
}



const translations: Record<Window['culture'], typeof NL> = {
    NL,
    FR
}

export const getTranslation = <T extends LanguageKey>(key: T): typeof NL[T] => {
    if (window.culture === "fr-BE")
        return translations["FR"][key]

    return translations["NL"][key]
}

export type LanguageKey = keyof typeof NL
